@tailwind base;
@tailwind components;
@tailwind utilities;

html, 
body {
    height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "Uni Sans";
  src: local("Uni Sans"),
    url("./assets/fonts/Archivo/Archivo-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: bold;
}

@font-face {
  font-family: "Uni Sans";
  src: local("Uni Sans"),
    url("./assets/fonts/Archivo/Archivo-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}


h1,
h2,h3, h4, h5 {
  font-family: "Uni Sans";
  font-weight: 900;
}

p{
  font-family: "Uni Sans";
}

#section-1 {
  background-image: url("./assets/images/section-1.png");
  background-repeat: no-repeat;
  background-position: center;
  mix-blend-mode: normal;
  background-size: cover;
}


.blur-ball-left {
  position: absolute;
  width: 1203px;
  height: 1203px;
  left: -50%;
  background: rgba(35, 215, 255, 0.16);
  filter: blur(108px);
}

.blur-ball-right {
  position: absolute;
  width: 1203px;
  height: 1203px;
  right: -50%;
  background: rgba(35, 215, 255, 0.16);
  filter: blur(108px);
}